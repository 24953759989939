@font-face {
  font-family: 'Fira Code';
  src: url('../fonts/FiraCode-Regular.woff2') format('woff2'),
  url("../fonts/FiraCode-Regular.woff") format("woff"),
  url('../fonts/FiraCode-Regular.ttf') format('ttf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Fira Code';
  src: url('../fonts/FiraCode-Light.woff2') format('woff2'),
  url("../fonts/FiraCode-Light.woff") format("woff"),
  url('../fonts/FiraCode-Light.ttf') format('ttf');
  font-weight: 300;
  font-style: normal;
}